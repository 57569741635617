import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./CheckIn.scss";
import {
  ArrowLeftIcon,
  UserIcon,
  ClockIcon,
  BellIcon,
  CameraIcon,
} from "@heroicons/react/24/outline";
import { GlobalContext } from "../context/GlobalContext";
import useGoBack from "../utils/useGoBack";
import Utility from "../utils/utility";
import useCountdownTimer from "../utils/useCountdownTimer";
import Sheets from "../components/Sheets/Sheets";
import ProfileCard from "../profile/ProfileCard";
import useHideNav from "../utils/useHideNav";
import EventsAPI from "../api/EventsAPI";
import { serverTimestamp } from "firebase/firestore";
import useNoSleep from "../utils/useNoSleep";
import useEventStreamingWithGroupChatCount from "./hooks/useEventStreamingWithGroupChatCount";
import SplashScreen from "../components/SplashScreen";
import { PrimaryButton, SecondaryButton } from "../components/Buttons/buttons";
import MasonaryGallery from "../components/MasonryGallery/Masonary";
import { UsersIcon } from "@heroicons/react/24/solid";
import { TextNotifications, TEXT_NOTIFICATION_FIELD } from "../components/Popup/Notifications";
import { Wizard, useWizard, } from 'react-use-wizard';


const CheckIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { event } = location.state || {};
  const { globalState } = useContext(GlobalContext);
  const [showSheet, setShowSheet] = useState(true);
  const [showCustomMatches, setShowCustomMatches] = useState(false);
  const goBack = useGoBack();
  const { eventID } = useParams();

  const [canStart, setCanStart] = useState(false);
  const [startBtnText, setStartBtnText] = useState("");
  useHideNav();
  const [loading, setLoading] = useState(true);
  const { resetWakeLock } = useNoSleep();
  const { eventStreaming } = useEventStreamingWithGroupChatCount(
    event,
    eventID,
    Utility.getUserType(null, globalState.user, event) || globalState.user?.group && globalState.user?.group[event.id || eventID]
  );

  const [timeLeft, , pauseTimer, , setCustomTimeLeft] = useCountdownTimer(0);





  useEffect(() => {
    resetWakeLock();
  }, []);

  useEffect(() => {
    if (eventStreaming) {
      setLoading(false);
    }
  }, [globalState.user, eventStreaming]);

  const calcTimeLeftToStart = useCallback((date) => {
    const eventDate = Utility.convertDate(date);
    const currentTime = new Date();
    const timeDiff = eventDate - currentTime;
    return Math.round(timeDiff / 1000);
  }, []);

  const getStartBtnText = useCallback(() => {
    let btnTxt = "Waiting for Start...";
    let eligibleToStart = true;
    const { activeSession, queue } = eventStreaming;


    if (!queue) {
      btnTxt = "Waiting for others...";
      eligibleToStart = false;
    }
    const userType = Utility.getUserType(queue, globalState.user, eventStreaming);

    const genderQueue = queue && queue[userType];
    if (genderQueue) {
      const idxOfCurrUser = genderQueue.findIndex(
        (u) => u == globalState.user?.userID
      );
      if (idxOfCurrUser < 0) {
        const isMutualMatch = eventStreaming.eventType == 1
        btnTxt = isMutualMatch ? `Waiting for Matches...` : `Waiting to be Added...`;
        eligibleToStart = false;
      }
    }
    if (activeSession == undefined || activeSession?.currentRound == undefined) {
      eligibleToStart = false;
    }
    if (activeSession && activeSession.currentRound && eligibleToStart) {
      btnTxt = `Joining Round ${activeSession.currentRound}...`;
    }
    setCanStart(eligibleToStart);
    setStartBtnText(btnTxt);
  }, [eventStreaming, globalState.user]);

  useEffect(() => {
    getStartBtnText();
  }, [eventStreaming.queue, eventStreaming.activeSession, getStartBtnText]);

  useEffect(() => {
    if (eventStreaming.date) {
      setCustomTimeLeft(calcTimeLeftToStart(eventStreaming.date));
      if (eventStreaming?.activeSession?.currentRound >= 0) {
        if (eventStreaming?.activeSession?.currentRound === 0) {
          if (startBtnText.length == 0) return;
          setStartBtnText("Starting...");
        }
        const startTimeoutId = setTimeout(() => {
          if (!canStart) return;
          if (!showSheet) {
            goToDateSession();
          } else {
            setStartBtnText(
              `Join Round ${eventStreaming.activeSession.currentRound}`
            );
          }
          if (!showCustomMatches) {
            goToDateSession();
          } else {
            setStartBtnText(
              `Join Round ${eventStreaming.activeSession.currentRound}`
            );
          }
        }, 10000);

        return () => clearTimeout(startTimeoutId);
      }
    }
  }, [eventStreaming, showSheet, showCustomMatches, canStart, startBtnText, calcTimeLeftToStart]);

  useEffect(() => {
    if (timeLeft < 0) {
      pauseTimer();
    }
  }, [timeLeft, pauseTimer]);

  const onStartSession = () => {
    if (timeLeft < 0 && canStart) {
      goToDateSession();
    }
  };

  const goToDateSession = () => {
    navigate(`/dates/date-session/${eventID}`);
  };

  const goToDateDetails = () => {
    navigate(`/dates/date-details/${eventStreaming.id}`, {
      state: { event: eventStreaming },
    });
  };

  const onUserUpdate = async (kv) => {
    await EventsAPI.onUpdateUserInQueue(
      kv,
      eventStreaming,
      globalState.user,
      globalState.user.userID
    );
  };

  if (loading) {
    return <SplashScreen />;
  }


  return (
    <div className="check-in" onClick={resetWakeLock}>
      <Toolbar goToDateDetails={goToDateDetails} />
      <GetStarted setShowSheet={setShowSheet} />
      {showSheet && (
        <WizardSheets
          showCue={true}
          eventStreaming={eventStreaming}
          onUserUpdate={onUserUpdate}
          setShowSheet={setShowSheet}
          globalState={globalState}
          onStartSession={onStartSession}
          timeLeft={timeLeft}
          startBtnText={startBtnText}
          canStart={canStart}
        />
      )}

    </div>
  );
};



const GetStarted = ({ setShowSheet }) => {

  return <div className="h-full flex items-center justify-center  ">
    <div className="flex flex-col gap-20">
      <span className="text-stone-400">
        Just few steps before we,
      </span>
      <span className="text-4xl font-extrabold animate-pulse bg-gradient-to-tr from-primary via-green-500 to-rose-500 bg-clip-text text-transparent">
        Get started
      </span>
      <PrimaryButton
        onClick={() => setShowSheet(true)}
        className="min-w-64"

      >
        Next
      </PrimaryButton>
    </div>

  </div>
}


const WizardSheets = ({ setShowSheet, eventStreaming, onUserUpdate, globalState, onStartSession, timeLeft, startBtnText, canStart }) => {
  const skipToStep4 = (() => {
    if (eventStreaming.eventUsers[globalState.user?.userID] && eventStreaming.eventUsers[globalState.user?.userID][TEXT_NOTIFICATION_FIELD]) {
      return 3
    } else {
      return 2
    }
  })()
  const skipToStep3 = (_ => {
    if (eventStreaming.eventType && eventStreaming.eventType == 1) {
      const userType = Utility.getUserType(null, globalState.user, eventStreaming);
      const isMutualMatch = eventStreaming.matchType == undefined || eventStreaming.matchType == 'mutual';
      const isUserType = userType == eventStreaming.matchType;
      if (isMutualMatch || isUserType) {

        return 1;
      }
    }

    return skipToStep4;
  })()


  return (
    <Sheets onCancel={() => setShowSheet(false)} >
      <Wizard>
        <WizardToolbar onNextSkipToStep={skipToStep3} children={<ProfileCard
          showCue={true}
          event={eventStreaming}
          onUserUpdate={onUserUpdate}

        />}
        />
        <WizardToolbar onNextSkipToStep={skipToStep4} children={<ProfileSelectorSheets eventStreaming={eventStreaming} globalState={globalState} hideSelectedProfiles={eventStreaming.activeSession?.at} />} />
        <WizardToolbar children={<TextNotificationsWrapper event={eventStreaming} />} />
        <WizardToolbar hideNextBtn={true} children={<CheckInContent
          globalState={globalState}
          eventStreaming={eventStreaming}


          onStartSession={onStartSession}
          timeLeft={timeLeft}
          startBtnText={startBtnText}
          canStart={canStart}
        />} />
      </Wizard>

    </Sheets>
  )
}

const WizardToolbar = ({ hideNextBtn, onNextSkipToStep, children }) => {
  const { handleStep, previousStep, nextStep, goToStep } = useWizard();
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props, handleStep });
    }
    return child;
  });

  const onNextStep = () => {
    if (onNextSkipToStep) {
      goToStep(onNextSkipToStep);
    } else {
      nextStep();
    }
  }
  return (
    <div className="relative h-full">
      {
        hideNextBtn
          ? null
          : <div className="absolute bottom-2 right-0 w-full p-4 z-10">
            <PrimaryButton
              onClick={onNextStep}
            >
              Next
            </PrimaryButton>
          </div>
      }

      {childrenWithProps}
    </div>
  )
}
const TextNotificationsWrapper = ({ event }) => {
  return (
    <div className="w-full h-1/2 flex flex-col gap-5 p-4 justify-between">
      <TextNotifications event={event} />
    </div>
  )
}
export const ProfileSelectorSheets = ({ eventStreaming, globalState, handleStep, hideSelectedProfiles }) => {
  let filteredUsers = [];

  const isUserInSitters = eventStreaming.sitters.includes(globalState.user.userID);
  const isUserInMovers = eventStreaming.movers.includes(globalState.user.userID);

  if (isUserInSitters) {
    filteredUsers = Object.keys(eventStreaming.eventUsers)
      .filter(user => eventStreaming.movers.includes(user))
      .map(user => eventStreaming.eventUsers[user]);
  }
  else if (isUserInMovers) {
    filteredUsers = Object.keys(eventStreaming.eventUsers)
      .filter(user => eventStreaming.sitters.includes(user))
      .map(user => eventStreaming.eventUsers[user]);
  }
  else {
    filteredUsers = Object.keys(eventStreaming.eventUsers)
      .map(user => eventStreaming.eventUsers[user]);
  }

  let images = filteredUsers.map((user) => ({
    id: user.userID,
    src: user.photoURL,
    // Assign a default or dynamic height if needed
  }));

  const [selectProfiles, setSelectProfiles] = useState([]);
  const onSelectionChange = useCallback((values) => {
    setSelectProfiles(values)
    EventsAPI.updateEvent(eventStreaming.id, {
      [`eventUsers.${globalState.user.userID}.consents`]: values,
      ["eventUsers.lastUpdated"]: serverTimestamp(),
    }).finally(() => {

    })

  }, [])
  const onSelectionDone = () => {
    //add selectProfiles to event using EventsAPI.updateEvent


    // setShowCustomMatches(false)
  }


  handleStep(() => {
    onSelectionDone()
  })
  return (
    <div className="w-full h-full">
      <div className="px-4 pt-2 text-left">
        <h3 className="text-lg font-bold text-stone-600">Make your selections</h3>
        <p className="text-sm text-stone-400">You can select more even after the event starts</p>
      </div>
      <MasonaryGallery hideSelectedImages={hideSelectedProfiles} defaultSelectedImages={eventStreaming.eventUsers[globalState.user.userID].consents} onSelectionChange={onSelectionChange} eventStreaming={eventStreaming} globalState={globalState} images={images} />

    </div>
  );
}




const Toolbar = ({ goToDateDetails }) => (
  <div className="toolbar">
    <button className="back-btn" onClick={goToDateDetails}>
      <ArrowLeftIcon className="icon" />
      Back
    </button>
  </div>
);

const CheckInContent = ({
  globalState,
  eventStreaming,
  onShowSheet,
  onShowCustomMatches,
  onStartSession,
  timeLeft,
  startBtnText,
  canStart,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  return (
    <div className="w-full h-full flex flex-col gap-5 p-4">
      <div className="dater-number mt-10 grow">
        <CheckInCard globalState={globalState} event={eventStreaming} />
        <div className="logo">
          <img src="/gist_logo.jpg" alt="gist logo" />
        </div>
      </div>



      <PrimaryButton
        onClick={onStartSession}
        showAnimation={!!eventStreaming.activeSession?.at && canStart}
      >
        <div className="flex items-center justify-center">
          {timeLeft > 0 ? (
            <>
              <ClockIcon className="w-5 h-5 mr-2" />
              {Utility.formatDateSessionTime(timeLeft, true)}
            </>
          ) : (
            <StartBtn label={startBtnText} />
          )}
        </div>
      </PrimaryButton>
    </div>
  )
};

const StartBtn = ({ label }) => (
  <>
    <BellIcon className="w-5 h-5 mr-2" />
    {label}
  </>
);

const CheckInCard = ({ globalState, event }) => {
  const [tableNumber, setTableNumber] = useState(-1);
  const [doesHavePair, setDoesHavePair] = useState(true);

  useEffect(() => {
    const table = getUserTableNumber();
    setDoesHavePair(table !== -1);
    setTableNumber(table);
  }, [globalState.user, event.queue]);

  const getUserTableNumber = () => {
    const userType = Utility.getUserType(event.queue, globalState.user);
    const eventUsers = Object.values(event.eventUsers);
    if (userType === Utility.userType.sitters && event.queue) {
      const sitterData = eventUsers.find(u => u.userID === globalState.user.userID);
      return event.groupCount === 1
        ? (sitterData ? sitterData?.badgeNumber : "-")
        : (sitterData ? sitterData?.table : "-");
    }
    return "-";
  };

  if (globalState.user == null) return null;


  return (
    <div className="number-display">
      {Utility.getUserType(event.queue, globalState.user, event) == Utility.userType.sitters ? (
        <SittersCard doesHavePair={doesHavePair} tableNumber={tableNumber} groupCount={event.groupCount} />
      ) : (
        <MoversCard />
      )}
    </div>
  );
};

const SittersCard = ({ doesHavePair, tableNumber, groupCount = 0 }) => (
  <div className="table-card">
    <p>{groupCount === 1 ? 'Your badge number is' : 'Your table number is'}</p>
    <h1 className="table-number">{tableNumber}</h1>
    <p className="pair">{groupCount === 1  
      ? 'Wait for the event to start'
      : 'Wait for your partner at your spot'}</p>
  </div>
);

const MoversCard = () => (
  <div className="px-3">
    <div className="bg-black bg-opacity-15 border-2 rounded-md border-gray-200 w-1/2 h-1/2 flex flex-col justify-center items-center  mx-auto my-5 py-3">
      <UserIcon className="w-24 h-24 text-gray-200 my-2" />
      <p className="text-center text-md font-semibold ">Table Number</p>
      <p className="text-center text-md font-semibold ">Name</p>
      <p className="text-center text-md font-thin ">Cues</p>
    </div>
    <div className="mx-2 m-3">
      <p className="text-md font-thin">
        When the session starts you will see your partner with their table
        number
      </p>
    </div>
  </div>
);


export default CheckIn;

