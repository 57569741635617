import React, { useState, useEffect, useContext, useCallback } from "react";

import "./DateSession.scss";
import useHideNav from "../../utils/useHideNav";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { Toolbar } from "./DateSessionHelpers";
import {
  EndSessionPopup,
  ReportUserPopup,
  SessionSheet,
} from "./DateSessionHelpers";
import MainSection from "./MainSection";
import EventsAPI from "../../api/EventsAPI";
import Utility from "../../utils/utility";
import Notifications, {
  TEXT_NOTIFICATION_FIELD,
} from "../../components/Popup/Notifications";

const ACTIVE_SESSION_FIELD = "activeSession";

export const DateSession = () => {
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { globalState } = useContext(GlobalContext);

  const [sessionTime, setSessionTime] = useState(4 * 60);
  const [eventData, setEventData] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [oppGenderUserProfiles, setOppGenderUserProfiles] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [currentDaterIdx, setCurrentDaterIdx] = useState(0);
  const [showIDCard, setShowIDCard] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [endSessionPopup, setEndSessionPopup] = useState(false);
  const [reportUserPopup, setReportUserPopup] = useState(false);
  const [reportUserText, setReportUserText] = useState("");
  const [hasActiveSession, setHasActiveSession] = useState(false);
  const [breaks, setBreaks] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showSessionSheet, setShowSessionSheet] = useState(false);
  const [sheetType, setSheetType] = useState("");
  const [queueLength, setQueueLength] = useState(0);

  const onCloseEndSessionPopup = () => setEndSessionPopup(false);
  const onCloseReportUserPopup = () => {
    setReportUserText("");
    setReportUserPopup(false);
  };
  const showNotifications = () => {
    setShowNotificationPopup(true);
  };
  const closeNotifications = () => {
    setShowNotificationPopup(false);
  };
  const onReportUserBtnPress = async () => {
    setReportUserPopup(true);
  };

  const onReportSend = async () => {
    const reportItem = {
      reportUserID: oppGenderUserProfiles[currentDaterIdx].userID,
      reportName: oppGenderUserProfiles[currentDaterIdx].name,
      note: reportUserText,
      reportedByUserID: globalState.user.userID,
      reportedByName: globalState.user.name,
    };
    await EventsAPI.updateEvent(eventID, {
      reports: arrayUnion(reportItem),
    });
    onCloseReportUserPopup();
  };

  const backToDates = () => {
    navigate(`/dates/date-details/${eventID}`, {
      state: { event: eventData },
    });
  };

  const backToCheckIn = () => {
    navigate(`/dates/check-in/${eventID}`, {
      state: { event: eventData },
    });
  };
  useHideNav();

  useEffect(() => {
    const unSub = EventsAPI.getEventStreaming(eventID, (e) => {
      setEventData(e);
    });
    addSession();
    return () => {
      if (unSub) unSub();
    };
  }, []);

  useEffect(() => {
    if (eventData.activeSession && eventData.activeSession.currentRound) {
      setHasActiveSession(true);

      setSessionTime(eventData.activeSession.timeLeft);
      if (eventData.isCompleted) {
        onEndSession();
        return;
      }
      setCurrentSessionData();
    }
    if (
      eventData.activeSession &&
      eventData.activeSession.currentRound === undefined
    ) {
      console.log("No active session. back to dates");
      backToDates();
    }
  }, [eventData.activeSession]);

  const calculateTimeLeft = useCallback(() => {
    if (!eventData.activeSession || !eventData.activeSession.at) return;

    const elapsedTime =
      (Date.now() - Utility.convertDate(eventData.activeSession.at).getTime()) /
      1000;
    return Math.max(
      0,
      Math.floor(eventData.activeSession.timeLeft - elapsedTime)
    );
  }, [eventData.activeSession, breaks]);

  useEffect(() => {
    let timer;
    if (eventData.activeSession) {
      const updateTimeLeft = () => {
        setTimeLeft(calculateTimeLeft());
      };

      updateTimeLeft();
      timer = setInterval(updateTimeLeft, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [eventData.activeSession, calculateTimeLeft]);

  useEffect(() => {
    if (!globalState.user) return;

    const userType = Utility.getUserType(eventData.queue, globalState.user);

    if (eventData.activeSession?.currentRound > 0) {
      const queueMatrix = eventData.queue.queueMatrix;
      const roundCount = Math.max(...Object.keys(queueMatrix).map(key => parseInt(key.split('_')[0]))) + 1;

      // Initialize maps to store matches for sitters and movers
      let sittersMatches = {};
      const moversMatches = {};

      const eventUsers = Object.values(eventData.eventUsers);

      const movers = eventData.groupCount === 1 ? eventData.queue.sitters : eventData.queue.movers;

      // Map mover IDs to their eventUsers data
      const moversData = movers.map(id => eventUsers.find(u => u.userID === id));

      // Map sitter IDs to their eventUsers data
      const sittersData = eventData.queue.sitters.map(id => eventUsers.find(u => u.userID === id));

      // Step 1: Parse the matrix to fill the matches
      Object.entries(queueMatrix).forEach(([key, moverIndex]) => {
        const [round, sitterIndex] = key.split('_').map(Number);

        // Initialize arrays if not already done
        if (!sittersMatches[sitterIndex]) sittersMatches[sitterIndex] = Array(roundCount).fill({ name: "On-Break", isOnBreak: true });
        if (!moversMatches[moverIndex]) moversMatches[moverIndex] = Array(roundCount).fill({ name: "On-Break", isOnBreak: true });

        // Assign matches for sitters and movers
        sittersMatches[sitterIndex][round] = moversData[moverIndex];
        moversMatches[moverIndex][round] = sittersData[sitterIndex];
      });

      const combinedMatches = [];

      if(eventData.groupCount === 1){
        // Get the maximum index from both arrays
        const maxSitterIndex = Math.max(...Object.keys(sittersMatches).map(Number));
        const maxMoverIndex = Math.max(...Object.keys(moversMatches).map(Number));
        const maxIndex = Math.max(maxSitterIndex, maxMoverIndex);

        // Initialize combinedMatches
        for (let i = 0; i <= maxIndex; i++) {
          combinedMatches[i] = Array(roundCount).fill({ name: "On-Break", isOnBreak: true });
        }

        // Copy sitters matches
        Object.keys(sittersMatches).forEach(index => {
          const i = Number(index);
          for (let round = 0; round < roundCount; round++) {
            if (sittersMatches[i][round].name !== "On-Break") {
              combinedMatches[i][round] = sittersMatches[i][round];
            }
          }
        });

        // Copy movers matches
        Object.keys(moversMatches).forEach(index => {
          const i = Number(index);
          for (let round = 0; round < roundCount; round++) {
            if (moversMatches[i][round].name !== "On-Break") {
              combinedMatches[i][round] = moversMatches[i][round];
            }
          }
        });

        sittersMatches = combinedMatches;
      }
      // Step 2: Determine the current user's matches based on their type
      const currentUserIndex = eventData.queue[userType]?.findIndex(
        (u) => u == globalState.user.userID
      ) ?? -1;

      if (currentUserIndex !== -1) {
        const matches = userType === 'sitters' || eventData.groupCount === 1 ? sittersMatches[currentUserIndex] : moversMatches[currentUserIndex];
        setOppGenderUserProfiles(matches);
      } else {
        setOppGenderUserProfiles([{ name: "On-Break", isOnBreak: true }]);
      }
    } 
  }, [eventData.queue, globalState.user, eventData.activeSession]);

  useEffect(() => {
    if (eventData.queue?.queueMatrix) {
      const queueMatrixKeys = Object.keys(eventData.queue.queueMatrix);
      const maxIndex = Math.max(...queueMatrixKeys.map(key => parseInt(key.split('_')[0])));
      setQueueLength(maxIndex + 1);
    } else if (eventData.eventType === 0 && eventData.queue) {
      setQueueLength(eventData.queue?.movers?.length || 0);
    }
  }, [eventData.queue]);

  useEffect(() => {
    if (oppGenderUserProfiles[currentDaterIdx]?.isOnBreak === true) {
      const userType = Utility.getUserType(eventData.queue, globalState.user);
      const isSitter = userType == Utility.userType.sitters;

      const consecutiveBreaks = Utility.getConsecutiveBreakCount(
        oppGenderUserProfiles,
        currentDaterIdx,
        isSitter ? "backward" : "forward"
      );
      setBreaks(consecutiveBreaks);
    }
  }, [currentDaterIdx, oppGenderUserProfiles]);

  const onExit = () => {
    setEndSessionPopup(true);
  };

  const onEndSession = async (toDateDetails = true) => {
    removeSession();
    onCloseEndSessionPopup();
    if (rating || reviewText.length) {
      const review = {
        userID: globalState.user.userID,
        name: globalState.user.name,
        rating,
        reviewText,
      };
      EventsAPI.updateEvent(eventID, { review: arrayUnion(review) });
    }
    toDateDetails ? backToDates() : backToCheckIn();
  };

  const removeSession = async () => {
    EventsAPI.updateEvent(eventID, {
      [`${ACTIVE_SESSION_FIELD}.activeUsers`]: arrayRemove(
        globalState.user.userID
      ),
    });
  };

  const addSession = () => {
    EventsAPI.updateEvent(eventID, {
      [`${ACTIVE_SESSION_FIELD}.activeUsers`]: arrayUnion(
        globalState.user.userID
      ),
    });
  };

  const getDaterIndex = () => {

    const userType = Utility.getUserType(eventData.queue, globalState.user);
    const queue = eventData.queue[userType];
    const userIndex = queue.findIndex(
      (u) => u == globalState.user?.userID
    );

    const isSitter = userType == Utility.userType.sitters;
    const daterIndex = Utility.calculateRoundRobinDistance(
      userIndex,
      eventData.activeSession.currentRound,
      queue.length,
      isSitter
    );

    return daterIndex;
  };

  const setCurrentSessionData = () => {
    const daterIndex = getDaterIndex();
    setCurrentDaterIdx(daterIndex);
    setCurrentRound(eventData.activeSession.currentRound);
  };

  const onSessionSheetCancel = () => {
    setShowSessionSheet(false);
  };

  const onShowSessionSheet = (type) => {
    setSheetType(type);
    setShowSessionSheet(true);
  };

  const onUpdateCueSheet = () => {
    setSheetType("profile");
    setShowSessionSheet(true);
  };

  const onShowGroupChat = () => {
    setSheetType("group-chat");
    setShowSessionSheet(true);
  };

  const onShowProfileSelector = () => {
    setSheetType("select-profiles");
    setShowSessionSheet(true);
  }

  const onAllowTextNotificationsChangee = (isChecked) => {
    const newUserState = {
      phone: isChecked ? globalState.user.phone : null,
      [TEXT_NOTIFICATION_FIELD]: isChecked,
    };
    EventsAPI.onUpdateUserInQueue(
      newUserState,
      eventData,
      globalState.user,
      globalState.user.userID
    );
  };

  const user = (function () {
    let usr = {};
    if (eventData === undefined || oppGenderUserProfiles === undefined) {
      return usr
    }

    usr = oppGenderUserProfiles[currentRound - 1];
    return usr
  })()

  return (
    <div className="date-session">
      <Toolbar
        onExit={onExit}
        user={user}
        setShowIDCard={setShowIDCard}
        currentRound={currentRound}
        onUpdateCue={onUpdateCueSheet}
        onSelectProfiles={onShowProfileSelector}
        onGroupChat={onShowGroupChat}
        onShowNotifications={showNotifications}
        totalRounds={queueLength}
        eventData={eventData}

      />
      <MainSection
        timeLeft={timeLeft}
        user={user}
        currentRound={currentRound}
        sessionTime={sessionTime}
        onReport={onReportUserBtnPress}
        onShowSessionSheet={onShowSessionSheet}
        showIDCard={showIDCard}
        setShowIDCard={setShowIDCard}
        groupCount={eventData.groupCount}
      />
      <EndSessionPopup
        isOpen={endSessionPopup}
        onClose={onCloseEndSessionPopup}
        onConfirm={onEndSession}
        rating={rating}
        setRating={setRating}
        reviewText={reviewText}
        setReviewText={setReviewText}
      />
      <ReportUserPopup
        isOpen={reportUserPopup}
        onClose={onCloseReportUserPopup}
        onConfirm={onReportSend}
        reportUserText={reportUserText}
        setReportUserText={setReportUserText}
      />
      <SessionSheet
        onCancel={onSessionSheetCancel}
        showSessionSheet={showSessionSheet}
        setShowSessionSheet={setShowSessionSheet}
        type={sheetType}
        eventData={eventData}
      />
      {showNotificationPopup && (
        <Notifications
          isOpen={true}
          onClose={closeNotifications}
          onChange={onAllowTextNotificationsChangee}
        />
      )}
    </div>
  );
};
